<template>
	<main>
		<HeaderTab :title="$t('invoice.bank_flow')" />

		<div id="content">
			<shutter-panel ref="shutterPanel" />
		</div>
	</main>
</template>

<script type="text/javascript">
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

	export default {
		name: 'BankFlow',
		mixins: [Shutter, ShutterInvoice],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.setUpBankFlowList()
			},
		},

		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
		}
	}

</script>